<template>
  <swiper ref="mySwiper" :options="swiperOptions">
    <swiper-slide v-for="(image) in this.images" :key="image['image']">
      <router-link :to="{ name: 'item', params: {itemId: image['link']}}">
        <img :src="image['image']" class="frontpageimage">
      </router-link>
      
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'Slider',
  props: {
    images: Array
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        autoplay: {
          delay: 5000,
        },
        loop: true,
        spaceBetween: 50,

        // Some Swiper option/callback...
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted() {
    // console.log('Current Swiper instance object', this.swiper)
    this.swiper.slideTo(3, 1000, false)
  }
}
</script>