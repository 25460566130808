<template>

  <div class="program">
    <h1>baselcitystudios Shop</h1>

    <div class="categories">

      <span v-if="selectedPage==='start'" class="selectedcategory category" v-on:click="toggleCategory('start')">Startseite</span>
      <span v-else class="category" v-on:click="toggleCategory('start')">Startseite</span>
      <span v-if="selectedPage==='Mikrofon'" class="selectedcategory category" v-on:click="toggleCategory('Mikrofon')">Mikrofone</span>
      <span v-else class="category" v-on:click="toggleCategory('Mikrofon')">Mikrofone</span>
      <span v-if="selectedPage==='Geräte'" class="selectedcategory category" v-on:click="toggleCategory('Geräte')">Geräte</span>
      <span v-else class="category" v-on:click="toggleCategory('Geräte')">Geräte</span>
      <span v-if="selectedPage==='Sonstiges'" class="selectedcategory category" v-on:click="toggleCategory('Sonstiges')">Sonstiges</span>
      <span v-else class="category" v-on:click="toggleCategory('Sonstiges')">Sonstiges</span>
    </div>
    <div v-if="selectedPage==='start'" class="start-content">
      <br>
      <span>Herzlich Willkommen bei den baselcitystudios.
<!--        <br>-->
<!--        <img class="img-fluid" src="/assets/lichtblick.PNG">-->
        <br>
        Wir führen einen digitalen Rampenverkauf durch. Es werden nur funktionstüchtige getestete Geräte verkauft. Angebote nehmen wir auf sale@baselcitystudios.com gerne ab sofort entgegen.</span>
      <div>
        Du findest uns auch nach Vereinbarung vor Ort an der Frankfurt Strasse 36 in Münchenstein/Schweiz oder auf <a id="facebook" href="https://www.facebook.com/baselcitystudios-114864731907300/">Facebook</a>.

        Wir freuen uns auf Deine Kontaktaufnahme.
      </div>
      <Slider
          class="mainSlider"
          :images = this.allPictures.slice(0,10)
      />
    </div>
    <div v-if="selectedPage!=='start'" class="search-container">
      <input v-model="searchinput" placeholder="Suche" type="text" v-on:keyup="updateView"/>
      <select v-model="sortBy" v-on:change="updateView">
        <option disabled value="">Sortieren nach:</option>
        <option>A-Z</option>
        <option>Günstigere Zuerst</option>
      </select>
      <span v-if="selectedPage!=='start'"  id="filter-toggle" v-on:click="filtersShown = !filtersShown">
        <span v-if="filtersShown">Filter verstecken</span>
        <span v-else>Filter anzeigen</span>
      </span>
    </div>

    <div  v-if="filtersShown" id="filters">
       <span v-for="filter in this.allFilters" :key="filter" v-on:click="toggleFilter(filter)">
      <span v-if="selectedFilters.includes(filter)" class="filter selected-filter">{{ filter }}</span>
      <span v-else class="filter">{{ filter }}</span>
    </span>
    </div>


    <div class="items-container">
      <router-link :to="{ name: 'item', params: {itemId: thing._id}}" v-for="thing in this.filteredItems" :key="thing._id" class="item-display" >
        <h2 class="shop-item-attr">{{ thing.Name }}</h2>
        <h3 class="mainpage-price shop-item-attr" v-if="thing.Preis_als_zahl !== 1000000">CHF {{ thing.Preis_als_zahl }}</h3>
        <h3 class="mainpage-price shop-item-attr" v-else-if="thing.Preis_als_zahl === 'Verkauft'">Verkauft</h3>
        <h3 class="mainpage-price shop-item-attr" v-else>Preis auf Anfrage</h3>

        <!--      <p>{{ thing.Preis_als_zahl}}</p>-->
        <!--      <p>{{ thing.Beschrieb }}</p>-->
      </router-link>
    </div>
    <!--    <img class="img-fluid" src="/assets/See_you_soon_elysia.png">-->

  </div>

</template>

<script>
// @ is an alias to /src


import Slider from "@/components/Slider";
export default {
  name: 'Home',
  components: {
    Slider
  },
  data() {
    return {
      urlstart: process.env.VUE_APP_URLSTART,
      items: [],
      filteredItems: [],
      allFilters: [],
      selectedFilters: [],
      searchinput: "",
      sortBy: "A-Z",
      filtersShown: false,
      selectedPage: "start",
      key: "102beae349dca2c0fa3abf2398dba4",
      allPictures: [],
    };
  },

  methods: {


    toggleFilter(filter) {

      let index = this.selectedFilters.indexOf(filter);
      //console.log(index);
      if (index === -1) {
        this.selectedFilters.push(filter);
      } else {
        this.selectedFilters.splice(index, 1);
      }
      // console.log(this.selectedFilters);
      this.updateView();
    },

    toggleCategory(category) {

      // console.log(category);
      //remove old filter

      // let index = this.selectedFilters.indexOf(this.selectedPage);
      // // console.log(index);
      // if (index !== -1) {
      //   this.selectedFilters.splice(index, 1);
      // }
      // this.selectedPage = category;
      //
      // if (category !== "start"){
      //   this.selectedFilters.push(category)
      // }

      this.selectedFilters = [];
      this.selectedFilters.push(category);
      this.selectedPage = category;

      this.updateView();
      localStorage.setItem('selectedPage', JSON.stringify(this.selectedPage));

    },


    //deletes all items that don't have tag matches and sorts
    updateView() {
      localStorage.setItem('selectedFilters', JSON.stringify(this.selectedFilters));
      this.filteredItems = [];

      if (this.selectedPage !== "start"){

        for (let item = 0; item < this.items.length; item++) {

          let thisItemHasAllTags = true;
          for (let filter = 0; filter < this.selectedFilters.length; filter++) {
            let currentFilter = this.selectedFilters[filter];
            if (this.items[item].Tags.indexOf(currentFilter) === -1) {
              if (this.items[item].category.indexOf(currentFilter) === -1){
                thisItemHasAllTags = false;
              }


            }

          }

          if (thisItemHasAllTags) {
            this.filteredItems.push(this.items[item])
          }
        }
        //sorting

        if (this.sortBy !== "") {
          if (this.sortBy === "A-Z") {
            this.filteredItems.sort(function (a, b) {
              return a.Name > b.Name;
            });
          } else {
            this.filteredItems.sort(function (a, b) {

              if (typeof a === "string" && typeof b === "string"){

                return a.Name > b.Name;
              } else if (typeof a === "string"){

                return false
              } else if (typeof b === "string"){

                return true
              } else {
                return parseFloat(a.Preis_als_zahl) > parseFloat(b.Preis_als_zahl);
              }

            });
          }
        }

        //search
        if (this.searchinput !== "") {
          let temp = [];
          for (let item = 0; item < this.filteredItems.length; item++) {
            if (this.filteredItems[item].Name.toLowerCase().indexOf(this.searchinput.toLowerCase()) !== -1 || this.filteredItems[item].Beschrieb.toLowerCase().indexOf(this.searchinput.toLowerCase()) !== -1) {
              temp.push(this.filteredItems[item])
            }
          }
          this.filteredItems = temp;
        }

      }
      localStorage.setItem('filteredItems', JSON.stringify(this.filteredItems));
      this.generateFilters();
    },

    generateFilters() {
      this.allFilters = [];
      for (let i = 0; i < this.filteredItems.length; i++) {
        for (let j = 0; j < this.filteredItems[i].Tags.length; j++) {
          let currentFilter = this.filteredItems[i].Tags[j]
          if (this.allFilters.indexOf(currentFilter) === -1) {
            this.allFilters.push(currentFilter);
          }

        }
      }
    },
    imageProcess(item) {
      for(let i in item.Bilder){
        this.allPictures.push("http://baselcitystudios.com" + item.Bilder[i].path)
      }
    },

    generateSlideshow() {
      for (let item in this.items) {
        if (!this.items[item].Bilder){
          break;
        }
        let newImageLinkPair = {link: this.items[item]._id, image: "http://baselcitystudios.com" + this.items[item].Bilder[0].path}
        this.allPictures.push(newImageLinkPair)
      }

      this.allPictures.sort(() => Math.random() - 0.5);

    }


  },


  mounted() {

    this.axios.get(this.urlstart + "/api/collections/get/shopitems?token=" + this.key).then((response) => {
      // console.log(this.urlstart + "/api/collections/get/shopitems?token=" + this.key);



      let items = response.data.entries.slice();
      for(let iter in items){
        //console.log(this.items[iter]);
        let item = items[iter];
        item.category ="Geräte";
        if (!isNaN(parseInt(item.Preis_als_zahl))){
          item.Preis_als_zahl = parseInt(item.Preis_als_zahl)
        } else {
          item.Preis_als_zahl = 1000000;
        }
        this.items.push(item);

      }

      this.items = items;
      this.axios.get(this.urlstart + "/api/collections/get/Sonstiges?token=" + this.key).then((response) => {


        let otherItems = response.data.entries;

        for (let i in otherItems) {
          // console.log(microphones[i]);
          let otherItem = otherItems[i];
          otherItem.category = "Sonstiges";
          if (!isNaN(parseInt(otherItem.Preis_als_zahl))) {
            otherItem.Preis_als_zahl = parseInt(otherItem.Preis_als_zahl);
          } else {
            otherItem.Preis_als_zahl = 1000000;
          }
          this.items.push(otherItem);
        }
        this.axios.get(this.urlstart + "/api/collections/get/Mikrofone?token=" + this.key).then((response) => {


          let microphones = response.data.entries;

          for (let i in microphones) {
            // console.log(microphones[i]);
            let microphone = microphones[i];
            microphone.category = "Mikrofon";
            if (!isNaN(parseInt(microphone.Preis_als_zahl))) {
              microphone.Preis_als_zahl = parseInt(microphone.Preis_als_zahl);
            } else {
              microphone.Preis_als_zahl = 1000000;
            }
            this.items.push(microphone);
          }

          if(localStorage.getItem("filteredItems") !== null){
            this.filteredItems = JSON.parse(localStorage.getItem('filteredItems'));
            this.generateFilters();
          } else {
            this.updateView();
          }

          if(localStorage.getItem("selectedFilters") !== null){
            // console.log(JSON.parse(localStorage.getItem('selectedFilters')).length)
            this.selectedFilters =  JSON.parse(localStorage.getItem('selectedFilters'));
            JSON.parse(localStorage.getItem('selectedFilters')).length === 1 ? this.filtersShown = false : this.filtersShown = true;
          }

          if(localStorage.getItem("selectedPage") !== null){
            this.selectedPage =  JSON.parse(localStorage.getItem('selectedPage'));
          }


          this.generateSlideshow();



        }).catch(error => {
          console.log(error)
        });


      }).catch(error => {
        console.log(error)
      });

    }).catch(error => {
      console.log(error)
    });




    // for (let i in this.items){
    //   this.filteredItems = this.items[i];
    // }

  },
  created() {

  },
  destroyed() {
  }
}
</script>
